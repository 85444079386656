/** @format */

import RowCard from '@atoms/RowCard';
import Scrollbar from '@atoms/Scrollbar';
import styled from '@emotion/styled';
import { TableProps } from '@molecules/Table/Table';
import React from 'react';

const StyledCardTable = styled.div`
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  max-height: 100%;
  overflow: scroll;
`;

function CardTable(props: TableProps) {
  return (
    <Scrollbar>
      <StyledCardTable>
        {props.data?.payload.map((d) => (
          <RowCard key={`${d.id}`} data={d._source} columns={props.columns} onClickRow={props.onClickRow} />
        ))}
      </StyledCardTable>
    </Scrollbar>
  );
}

export default CardTable;
