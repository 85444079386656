/** @format */

import FlexBox from '@atoms/FlexBox';
import { cssVars } from '@atoms/GlobalStyles';
import TableImage from '@atoms/TableImage';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import styled from '@emotion/styled';
import React from 'react';
import { Row } from 'react-table';

interface RowCardProps {
  data: any;
  columns: any;
  onClickRow?(row: Row): void;
}

const StyledRowCard = styled.div`
  // display: flex;
  //flex: 1 1 0;
  //min-width: 200px;
  //max-width: 400px;
  // padding: 4px;
  // gap: 2px;
  // border-radius: 3px;
  font-size: 12px;
  background: ${cssVars.lightGrey}22;
  border: 1px solid ${cssVars.lightGrey};
  //:last-of-type {
  //  flex-grow: 0;
  //}
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

const CardHeader = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  //justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${cssVars.lightGrey};
  color: ${cssVars.black};
  > span {
    font-weight: bold;
    padding-left: 12px;
    //color: ${cssVars.darkGrey};
    //font-size: 1rem;
  }
`;

const LogoContainer = styled.div`
  flex: 0 0 auto;
  width: fit-content;
  min-width: 25px;
  //max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  word-break: break-all;
  word-wrap: anywhere;
  padding: 4px;
  box-sizing: border-box;
  //display: grid;
  //grid-template-columns: max-content 1fr;
`;

const Label = styled.span`
  font-weight: bold;
  //background: ${cssVars.lightGrey};
  color: ${cssVars.grey};
  //text-align: right;
  padding: 2px 12px 2px 2px;
`;

const Value = styled.span`
  padding: 2px;
  max-height: 48px;
  //min-width: 300px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
  //line-height: 20px;
  -webkit-line-clamp: 1;
  z-index: 1;
  //transition: max-height 1s ease;
`;

function RowCard(props: RowCardProps) {
  const { tableStrings } = useStrings();

  const titleCol = props.columns.find((col) => col.cardTitle);
  return (
    <StyledRowCard
      onClick={() => {
        props.onClickRow ? props.onClickRow({ original: { _source: props.data } } as any as Row) : null;
      }}
    >
      {titleCol && (
        <CardHeader>
          {'logo' in props.data && typeof props.data.logo === 'string' && (
            <LogoContainer>
              <TableImage resource={props.data.logo} maxHeight={'20px'} />
            </LogoContainer>
          )}
          <span>{props.data[titleCol.id]}</span>
        </CardHeader>
      )}

      <InfoContainer>
        {props.columns
          .filter((c) => !['logo', titleCol?.id].includes(c.id))
          .map((col) => {
            return (
              <FlexBox flexDirection={'column'} width={'100%'} key={`${props.data.id}${col.id}`}>
                <Label>{tableStrings[col.id]}</Label>
                {col.cardRender ? (
                  col.cardRender({ row: props.data, value: props.data[col.id] })
                ) : col.Cell ? (
                  // put the data in original._source to mimic ES results, but also spread at the top level of the row object just in case
                  col.Cell({ row: { original: { _source: props.data }, ...props.data }, value: props.data[col.id] })
                ) : (
                  <Value>{props.data[col.id]}</Value>
                )}
              </FlexBox>
            );
          })}
      </InfoContainer>
    </StyledRowCard>
  );
}

export default RowCard;
