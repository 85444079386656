/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/react';

interface SortingArrowsProps {
  sortId: string;
  sort: Record<string, 'asc' | 'desc'>;
}

const StyledSortingArrows = styled.div``;

function SortingArrows(props: SortingArrowsProps) {
  if (!props.sort) {
    return null;
  }
  if (!(props.sortId in props.sort)) {
    return (
      <FontAwesomeIcon
        css={css`
          margin-right: 18px;
          color: #969696;
          //color: ${cssVars.lightGrey};
        `}
        icon={['far', 'sort']}
      />
    );
  }
  return props.sort[props.sortId] === 'desc' ? (
    <FontAwesomeIcon
      css={css`
        margin-right: 18px;
      `}
      icon={['fad', 'sort-down']}
    />
  ) : (
    <FontAwesomeIcon
      css={css`
        margin-right: 18px;
      `}
      icon={['fad', 'sort-up']}
    />
  );
}

export default SortingArrows;
