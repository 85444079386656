/** @format */

export enum ResourceStatus {
  OK = 'ok',
  PENDING = 'pending',
  QUARANTINE = 'quarantine',
  // Send Notification
  // If there is a URL for the resource, but it cannot be found
  NOT_FOUND = 'notFound',
  // Send Notification
  // If there is no URL for the resource, meaning it was either never included or has been previously deleted
  // TODO is it possible to distinguish between these two cases to show a different message when an attempted upload was deleted for all fetches not just the first
  NULL = 'null',
  NEW = 'new',
}
