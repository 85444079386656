/** @format */

import styled from '@emotion/styled';

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  > * {
    margin-right: 8px;
  }
`;
