/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

export const StyledThead = styled.thead`
  background-color: ${cssVars.offWhite};
  user-select: none;
  > tr:hover {
    background-color: ${cssVars.offWhite};
  }
`;
