/** @format */

import Button from '@atoms/Button';
import ErrorBoundary from '@atoms/ErrorBoundary/ErrorBoundary';
import { cssVars } from '@atoms/GlobalStyles';
import { useWindowDimensions } from '@common/application/hooks/useWindowDimensions';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SortableTh from '@molecules/Table/SortableTh';
import SortingArrows from '@molecules/Table/SortingArrows';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import CardTable from '@molecules/CardTable';
import { FormattedMessage } from 'react-intl';
import { Column, ColumnInstance, Row, useColumnOrder, useExpanded, useRowSelect, useSortBy, useTable } from 'react-table';
import { StyledPagination, StyledTable, StyledTbody, StyledTd, StyledTh, StyledThead, StyledTr, StyledTrFooter } from './styled';
import PerfectScrollbar from 'react-perfect-scrollbar';

export interface SortableColumn extends ColumnInstance {
  sortId?: string;
}

export interface TableProps {
  columns: SortableColumn[] | Column<{}>[];
  // columns: Column<{}>[];
  data: { payload: Record<string, unknown>[]; last_page: number; total: { value: number } };
  previousPage?: () => void;
  nextPage?: (data) => void;
  pageHistory?: Record<string, unknown>[];
  loading?: boolean;
  className?: string;
  unpaginated?: boolean;
  onClickRow?: (row: Row) => void;
  // These are intended to be the state and dispatch returned from useTableSorting custom hook
  onSort?(sort: Record<string, 'asc' | 'desc'> | null): void;

  sort?: Record<string, 'asc' | 'desc'>;

  defaultSort?: { columnId: string; ascending: boolean };

  disableSorting?: boolean;

  renderRowSubComponent?(row: Row): ReactElement;

  allowRenderToCard?: boolean;
}

interface PaginationDataProps {
  historyLength: number;
  size: number;
  total: number;
}

const PaginationData = (props: PaginationDataProps) => {
  const start = props.historyLength * props.size;
  const end = start + props.size;
  return (
    <span>
      {start} - {end < props.total ? end : props.total} of {props.total}
    </span>
  );
};

const size = 10;

export function Pagination<T>(props: {
  data: {
    payload: T[];
    last_page: number;
    total: {
      value: number;
    };
  };
  pageHistory: Record<string, unknown>[];
  previousPage: () => void;
  nextPage: (data) => void;
}) {
  return (
    <StyledPagination>
      {props.data ? (
        <>
          <PaginationData historyLength={props.pageHistory?.length ?? 0} size={size} total={props.data.total?.value} />
          <Button
            css={css`
              width: 28px;
              justify-content: center;
            `}
            onClick={props.previousPage}
            disabled={props.pageHistory?.length === 0}
          >
            <FontAwesomeIcon icon={['fas', 'angle-left']} />
          </Button>
          <Button
            css={css`
              width: 28px;
              justify-content: center;
            `}
            onClick={props.nextPage}
            disabled={(props.pageHistory?.length ?? 0) * size + (props.data?.payload ?? ([] as Array<unknown>)).length === props.data?.total?.value}
          >
            <FontAwesomeIcon icon={['fas', 'angle-right']} />
          </Button>
        </>
      ) : (
        <FormattedMessage defaultMessage="loading…" description="Loading text for tables" />
      )}
    </StyledPagination>
  );
}

export function Table(props: TableProps) {
  const initialState = { pageIndex: 0, sortBy: props.defaultSort ? [{ id: props.defaultSort.columnId, desc: !props.defaultSort.ascending }] : [] };
  // Memoize data to prevent infinite re-renders on error?
  const data = useMemo(() => (Array.isArray(props.data?.payload) ? props.data?.payload : []), [props.data?.payload]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns, columns, setSortBy } = useTable(
    {
      columns: props.columns ?? [],
      data: data ?? [],
      // @ts-ignore
      initialState,
      manualPagination: true,
      pageCount: props.data?.last_page,
      autoResetExpanded: false,
      manualSortBy: !!props.onSort,
      disableSortBy: !!props.disableSorting,
      disableMultiSort: true,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    useColumnOrder,
    // () => {},
  );
  const _props = props;

  const clearColumnSorting = () => {
    setSortBy([]);
  };
  const { width } = useWindowDimensions();

  //Find the column that has sorting applied
  // Cast as SortableColumn to allow sortId property
  const sortedCol = columns.find((column) => column.isSorted) as SortableColumn;
  // There has to be a better way to do this, but I can't find one
  useEffect(() => {
    if (!props.onSort) return;
    //pass the sorted column's info to the onSort function, so it can be passed to the query in parent component
    props.onSort(sortedCol ? { [sortedCol?.sortId ?? sortedCol.id ?? 'createdAt']: sortedCol?.isSortedDesc ? 'desc' : 'asc' } : null);
    //  React wants props to be in this deps array. Do not give react what it wants unless you are a big fan of infinite loops.
  }, [props.onSort, sortedCol?.id, sortedCol?.isSortedDesc]);

  const setSortToDefault = () => {
    if (!props.defaultSort) return;
    const sortCol = columns.find((col) => col.id === props.defaultSort?.columnId) as SortableColumn;
    if (!sortCol) return;
    props.onSort({ [sortCol.sortId]: props.defaultSort.ascending ? 'asc' : 'desc' });
  };

  const sortColumn = (column: SortableColumn) => {
    if (!props.sort || !props.onSort) return;
    let sortObject;
    if (!props.sort.hasOwnProperty(column.sortId)) {
      sortObject = { [column.sortId]: 'asc' };
    } else if (props.sort[column.sortId] === 'asc') {
      sortObject = { [column.sortId]: 'desc' };
    } else {
      return setSortToDefault();
    }
    props.onSort(sortObject);
  };

  useEffect(() => {
    setSortToDefault();
  }, [props.defaultSort]);

  const TableElement = useMemo(
    () => (
      <StyledTable {...getTableProps()}>
        <StyledThead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => {
              const headerGroupProps = headerGroup.getHeaderGroupProps();
              return (
                // Apply the header row props
                <StyledTr key={headerGroupProps.key} {...headerGroupProps}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      const columnHeaderProps = column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        // Apply the header cell props
                        <SortableTh
                          sort={props.sort}
                          columnHeaderProps={columnHeaderProps}
                          column={column}
                          sortColumn={sortColumn}
                          onSort={props.onSort}
                          key={columnHeaderProps.key}
                        />
                      );
                    })
                  }
                </StyledTr>
              );
            })
          }
        </StyledThead>
        <StyledTbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                // Apply the row props
                [
                  <StyledTr key={rowProps.key} onClick={props.onClickRow ? () => props.onClickRow(row) : null} {...rowProps}>
                    {row.cells.map((cell) => {
                      const props = cell.getCellProps();
                      return (
                        <StyledTd key={props.key} {...props}>
                          {cell.render('Cell')}
                        </StyledTd>
                      );
                    })}
                  </StyledTr>,
                  (row as any as { isExpanded: boolean }).isExpanded ? (
                    <StyledTr key={`${rowProps.key}-expand`}>
                      <td colSpan={visibleColumns.length}>
                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {_props.renderRowSubComponent?.(row)}
                      </td>
                    </StyledTr>
                  ) : undefined,
                ]
              );
            })
          }
        </StyledTbody>
      </StyledTable>
    ),
    [_props, getTableBodyProps, getTableProps, headerGroups, prepareRow, props, rows, visibleColumns.length],
  );
  //
  // if (window?.innerWidth < 1000) {
  //   return <CardTable {...props} />;
  // }

  return (
    <ErrorBoundary>
      <div
        className={props.className}
        css={css`
          position: relative;
          width: 100%;
          box-sizing: border-box;
          height: fit-content;
        `}
      >
        {props.loading ? (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: ${cssVars.black};
              color: white;
            `}
          >
            <FontAwesomeIcon size="2x" icon={['fas', 'arrows-rotate']} spin />
          </div>
        ) : null}
        <PerfectScrollbar>{props.allowRenderToCard && width < 1000 ? <CardTable {...props} /> : TableElement}</PerfectScrollbar>
        <StyledTrFooter>
          <div>
            {props.unpaginated || (props?.data?.total?.value ?? 0) < size ? null : (
              <Pagination data={props.data} pageHistory={props.pageHistory} previousPage={props.previousPage} nextPage={props.nextPage} />
            )}
          </div>
        </StyledTrFooter>
      </div>
    </ErrorBoundary>
  );
}

export default Table;
