/** @format */

import Tooltip from '@atoms/Tooltip/Tooltip';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import React, { useEffect, useRef } from 'react';
import { mergeProps, TooltipTriggerProps, useTooltipTrigger } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';

interface TooltipButtonProps extends TooltipTriggerProps {
  tooltip: string | EmotionJSX.Element;
  children: React.ReactNode;
  onClick?(): void;
}

function TooltipButton({ delay = 0, closeDelay = 0, children, onClick, ...props }: TooltipButtonProps) {
  const ref = React.useRef(null);
  const state = useTooltipTriggerState({ ...props, delay, closeDelay });
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);
  return (
    <>
      {React.isValidElement(children) &&
        React.cloneElement<any>(children, {
          ref,
          ...triggerProps,
          onClick: onClick ? onClick : triggerProps.onClick,
        })}
      {state.isOpen && (
        <Tooltip state={state} {...mergeProps(tooltipProps, props)} triggerRef={ref}>
          <div>{props.tooltip}</div>
        </Tooltip>
      )}
    </>
  );
}

export default TooltipButton;
