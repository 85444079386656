/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

export const StyledTr = styled.tr`
  border-bottom: 1px solid ${cssVars.lightGrey};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

  :hover {
    background-color: ${cssVars.lightBlue};
  }
`;
