/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SortingArrows from '@molecules/Table/SortingArrows';
import { StyledTh } from '@molecules/Table/styled';
import { SortableColumn } from '@molecules/Table/Table';
import React from 'react';
import { TableHeaderProps } from 'react-table';

interface ThProps {
  onSort?(sort: Record<string, 'asc' | 'desc'>): void;
  sort: Record<string, 'asc' | 'desc'>;
  columnHeaderProps: TableHeaderProps;
  column: SortableColumn;
  sortColumn(column: SortableColumn): void;
}

function SortableTh(props: ThProps) {
  if (!!props.onSort) {
    return (
      <StyledTh
        // key={columnHeaderProps.key}
        {...props.columnHeaderProps}
        onClick={() => {
          props.sortColumn(props.column);
        }}
      >
        <span
          css={css`
            gap: 12px;
          `}
        >
          {
            // Render the header
            props.column.render('Header')
          }
          {props.column.canSort && (
            <span>
              <SortingArrows sortId={props.column.sortId} sort={props.sort} />
            </span>
          )}
        </span>
      </StyledTh>
    );
  }
  return (
    <StyledTh
      // key={columnHeaderProps.key}
      {...props.columnHeaderProps}
    >
      <span
        css={css`
          gap: 12px;
        `}
      >
        {
          // Render the header
          props.column.render('Header')
        }
        {props.column.canSort && (
          <span>
            {props.column.isSorted ? (
              props.column.isSortedDesc ? (
                <FontAwesomeIcon
                  css={css`
                    margin-right: 18px;
                  `}
                  icon={['fad', 'sort-down']}
                />
              ) : (
                <FontAwesomeIcon
                  css={css`
                    margin-right: 18px;
                  `}
                  icon={['fad', 'sort-up']}
                />
              )
            ) : (
              <FontAwesomeIcon
                css={css`
                  margin-right: 18px;
                  color: #969696;
                  //color: ${cssVars.lightGrey};
                `}
                icon={['far', 'sort']}
              />
            )}
          </span>
        )}
      </span>
    </StyledTh>
  );
}

export default SortableTh;
