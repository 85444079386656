/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: ${cssVars.white};
`;
