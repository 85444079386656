/** @format */

import styled from '@emotion/styled';

export const StyledTh = styled.th`
  padding: 8px;
  text-align: start;
  font-weight: normal;
  white-space: nowrap;

  > * {
    display: inline-flex;
  }

  > span {
  }

  > svg {
    margin-left: 12px;
  }
`;
